@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    @font-face {
        font-family: "Pinar";
        src: url("./Fonts/Pinar/Pinar-VF.woff2") format("woff2");
        font-weight: 300;
        font-display: swap;
    }

    @font-face {
        font-family: "PinarMedium";
        src: url("./Fonts/Pinar/Pinar-VF.woff2") format("woff2");
        font-weight: 400;
        font-display: swap;
    }

    @font-face {
        font-family: "PinarSemiBold";
        src: url("./Fonts/Pinar/Pinar-VF.woff2") format("woff2");
        font-weight: 500;
        font-display: swap;
    }

    @font-face {
        font-family: "PinarBold";
        src: url("./Fonts/Pinar/Pinar-VF.woff2") format("woff2");
        font-weight: 600;
        font-display: swap;
    }

    @font-face {
        font-family: "Morabba";
        src: local("Morabba"), url("./Fonts/Morabba/Morabba-Regular.woff2") format("woff2");
        font-display: swap;
    }

    @font-face {
        font-family: "MorabbaMedium";
        src: local("Morabba Medium"), url("./Fonts/Morabba/Morabba-Medium.woff2") format("woff2");
        font-display: swap;
    }

    @font-face {
        font-family: "MorabbaSemiBold";
        src: local("Morabba SemiBold"), url("./Fonts/Morabba/Morabba-SemiBold.woff2") format("woff2");
        font-display: swap;
    }

    @font-face {
        font-family: "MorabbaBold";
        src: local("Morabba Bold"), url("./Fonts/Morabba/Morabba-Bold.woff2") format("woff2");
        font-display: swap;
    }

    * {
        font-family: "PinarMedium";
        -moz-font-feature-settings: "ss01";
        -webkit-font-feature-settings: "ss01";
        font-feature-settings: "ss01";
    }
}

@layer components {
    .BaseContainer {
      @apply w-[85%] lg:w-[80%] xl:w-[1278px] mx-auto;
    }
    .HeroBackground {
        background: 
        linear-gradient(to bottom, rgba(0, 5, 17, 0.8), rgba(0, 5, 17, 0.8)),
        url('../public/assets/images/video/2.gif');
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 900px;
    } 

    .mySwiper{
        width: 100%;
        margin: 0 auto;
    }
    .Topbar{
        background: 
        linear-gradient(to bottom, rgba(0, 5, 17, 0.8), rgba(0, 5, 17, 0.8)),
        url('../public/assets/images/topbar.webp');
        background-position: center;
        background-size: cover;
    }
    .AboutImages{
       @apply h-[150px] md:h-[240px] lg:h-[280px] w-full object-cover rounded-3xl hover:opacity-70 transition-opacity;
    }
    .AboutUsSwiper{
        --swiper-pagination-color: #47F6AC !important;
        --swiper-pagination-bullet-inactive-color: rgba(63, 211, 143, 0.5) !important;
        --swiper-pagination-bullet-inactive-opacity: 1 !important;
        --swiper-pagination-bullet-size: 12px !important;
        --swiper-pagination-bullet-horizontal-gap: 4px !important;
        --swiper-pagination-bottom: 0px !important;
    }
}